<template>
  <div class="content_body inventoryDetail">
    <div class="nav_header">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" size="small" :model="searchForm" @keyup.enter.native="searchDataList">
            <el-form-item v-if="EntityList.length > 1" label="仓库/门店" prop="EntityID">
              <el-select size="small" value-key="ID" v-model="searchForm.EntityName" filterable placeholder="请选择仓库/门店" @change="handleSelectEntity" clearable>
                <el-option value-key="ID" v-for="item in EntityList" :key="item.ID" :label="item.EntityName" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品">
              <el-input v-model="searchForm.ProductName" placeholder="请输入产品名称、别名" clearable @clear="searchDataList"></el-input>
            </el-form-item>
            <el-form-item label="出入库类型">
              <el-select v-model="searchForm.InventoryType" clearable filterable placeholder="请选择出入库类型" :default-first-option="true" @change="searchDataList">
                <el-option v-for="item in InventoryTypes" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="制单日期">
              <el-date-picker v-model="searchForm.DateTime" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" clearable @change="searchDataList"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchDataList" v-prevent-click>搜索</el-button>
            </el-form-item>

            <el-form-item>
              <el-button v-if="InventoryDetailExcel" type="primary" size="small" :loading="downloadLoading" @click="downloadExcel">导 出 </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <el-table class="martp_10" size="small" :data="data_list" tooltip-effect="light">
      <el-table-column prop="ProductName" label="产品">
        <template slot-scope="scope">
          <div>{{scope.row.ProductName}}<span v-if="scope.row.Alias" class="font_12 color_999">({{scope.row.Alias}})</span></div>
          <div v-if="scope.row.Specification" class="font_12 color_999">规格：{{scope.row.Specification}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="MinimumUnitName" label="单位"></el-table-column>
      <el-table-column prop="BillID" label="单据号"></el-table-column>
      <el-table-column prop="EntityName" label="仓库/门店"></el-table-column>
      <el-table-column prop="InventoryType" label="出入库类型"></el-table-column>
      <el-table-column prop="MinimumUnitQuantity" label="出入库">
        <template slot-scope="scope">
          <span class="color_green" v-if="scope.row.MinimumUnitQuantity>0">+{{scope.row.MinimumUnitQuantity}}</span>
          <span class="color_red" v-else-if="scope.row.MinimumUnitQuantity<0">{{scope.row.MinimumUnitQuantity}}</span>
          <span v-else>{{scope.row.MinimumUnitQuantity}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="StockQuantity" label="剩余量"></el-table-column>
      <el-table-column prop="BillDate" label="制单时间"></el-table-column>
      <el-table-column prop="EmployeeName" label="操作人"></el-table-column>

    </el-table>

    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="HandleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>

  </div>
</template>

<script>
//
import API from "@/api/PSI/Inventory/inventoryDetail";
import APIStorage from "@/api/PSI/Purchase/storage";
import dateUtil from "@/components/js/date";
import permission from "@/components/js/permission.js";

export default {
  name: "InventoryDetail",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.InventoryDetailExcel = permission.permission(
        to.meta.Permission,
        "PSI-Inventory-InventoryDetail-Export"
      );
    });
  },
  data() {
    return {
      downloadLoading: false,
      loading: false,
      searchForm: {
        ProductName: "",
        InventoryType: "",
        EntityID: "",
        EntityName: "",
        DateTime: "",
      },
      InventoryTypes: [
        "采购入库",
        "盘盈入库",
        "要货入库",
        "调拨入库",
        "其他入库",
        "盘平",
        "盘亏出库",
        "销售出库",
        "配送出库",
        "调拨出库",
        "领料出库",
        "报损出库",
        "其他出库",
        "退货入库",
        "加盟商采购入库",
        "加盟商采购出库",
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() ? true : false;
        },
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      EntityList: [],
      data_list: [],
      InventoryDetailExcel: false,
    };
  },
  /**  方法集合  */
  methods: {
    /** 搜索   */
    searchDataList() {
      let that = this;
      that.paginations.page = 1;
      that.get_stockList_inventoryDetail_netWork();
    },
    /**  修改 仓库  */
    handleSelectEntity(row) {
      let that = this;
      that.searchForm.EntityID = row.ID;
      that.searchDataList();
    },
    /**   修改分页 */
    HandleCurrentChange(page) {
      let that = this;
      that.paginations.page = page;
      that.get_stockList_inventoryDetail_netWork();
    },

    /** ++++++++++++++ ============================================= +++++++++++++ */

    /**  仓库列表  */
    getStorageEntityNetwork: function () {
      var that = this;
      var params = {};
      APIStorage.getpurchaseStorageEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.EntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**     */
    get_stockList_inventoryDetail_netWork: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        ProductName: that.searchForm.ProductName,
        InventoryType: that.searchForm.InventoryType,
        EntityID: that.searchForm.EntityID,
        StartDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[0],
        EndDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[1],
      };
      API.get_stock_inventoryDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.data_list = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /** 数据导出 */
    downloadExcel() {
      var that = this;
      var params = {
        ProductName: that.searchForm.ProductName,
        InventoryType: that.searchForm.InventoryType,
        EntityID: that.searchForm.EntityID,
        StartDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[0],
        EndDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[1],
      };

      that.downloadLoading = true;
      API.exportInventoryDetailExcel(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "出入库明细.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function () {
          that.downloadLoading = false;
        });
    },
  },
  /**  实例被挂载后调用  */
  mounted() {
    var that = this;
    that.InventoryDetailExcel = permission.permission(
      that.$route.meta.Permission,
      "PSI-Inventory-InventoryDetail-Export"
    );
    // var date = new Date(),
    // y = date.getFullYear(),
    // m = date.getMonth();
    // that.searchForm.DateTime = [dateUtil.formatDate.format(new Date(y, m, 1),"YYYY-MM-DD"),dateUtil.formatDate.format(new Date(),"YYYY-MM-DD")];
    that.get_stockList_inventoryDetail_netWork();
    that.getStorageEntityNetwork();
  },
};
</script>

<style lang="scss">
</style>
